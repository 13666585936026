import type React from "react";
import * as r from "react/jsx-runtime";
import { wrap } from "./wrap";
import type { Style } from "../style";

export const jsx = wrap((r as any).jsx);
export const jsxs = wrap((r as any).jsxs);
export const Fragment = (r as any).Fragment;

export namespace JSX {
  export type ElementType = string | React.JSXElementConstructor<any>;

  export interface Element extends globalThis.JSX.Element {}

  export interface ElementClass extends globalThis.JSX.ElementClass {}

  export interface ElementAttributesProperty
    extends globalThis.JSX.ElementAttributesProperty {}

  export interface ElementChildrenAttribute
    extends globalThis.JSX.ElementChildrenAttribute {}

  export type LibraryManagedAttributes<C, P> =
    globalThis.JSX.LibraryManagedAttributes<C, P>;

  export interface IntrinsicAttributes
    extends globalThis.JSX.IntrinsicAttributes {}

  export interface IntrinsicClassAttributes<T>
    extends globalThis.JSX.IntrinsicClassAttributes<T> {}

  export type IntrinsicElements = {
    [K in keyof globalThis.JSX.IntrinsicElements]: Omit<
      globalThis.JSX.IntrinsicElements[K],
      "className"
    > & {
      css?: Style;
      __experimental_webkitScrollbarCss?: Style;
      __experimental_webkitScrollbarCorner?: Style;
      __experimental_webkitScrollbarThumb?: Style;
      __experimental_webkitScrollbarThumbHover?: Style;
      __experimental_webkitScrollbarButton?: Style;
      __experimental_placeholderCss?: Style;
      __experimental_webkitSearchCancelButtonCss?: Style;
      __experimental_webkitSearchDecorationCss?: Style;
      __experimental_beforeCss?: Style;
      __experimental_afterCss?: Style;
    };
  };
}
