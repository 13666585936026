/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { Temporal } from "@js-temporal/polyfill";
import { cssFns, type Style } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { useEffect, useRef, type ReactNode } from "react";
import { mergeProps, useHover, usePress } from "react-aria";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

const useCellStyle = ({
  isSelected,
  isHovered,
  isDisabled,
  isFocused,
  isReadOnly,
}: {
  isHovered?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  isReadOnly?: boolean;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const backgroundColor =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (isSelected && uiColors.controlMain) ||
    (isHovered && !isDisabled && uiColors.backgroundMinor) ||
    uiColors.background;

  return {
    ...cssFns.border({
      width: "2px",
      style: "solid",
      color: isFocused ? uiColors.text : "transparent",
    }),
    ...(isDisabled && { opacity: "0.5" }),
    ...cssFns.overflow("hidden"),
    ...typo({
      level: "body2",
      weight: "regular",
      density: "tight",
    }),
    boxSizing: "border-box",
    cursor: isSelected || isDisabled || isReadOnly ? "default" : "pointer",
    outlineStyle: "none",
    backgroundColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: isSelected ? uiColors.textOnControlMain : "inherit",
  };
};

type CellProps = {
  value: Temporal.PlainDate;
  isSelected: boolean;
  isDisabled: boolean;
  isFocused: boolean;
  style?: Style;
  onPress: (date: Temporal.PlainDate) => void;
};

const BaseCell = ({
  value,
  isSelected,
  isDisabled,
  isFocused,
  style = {},
  children,
  onPress,
}: CellProps & {
  children: ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isHovered, hoverProps } = useHover({});

  const { pressProps } = usePress({
    onPress: isDisabled ? undefined : () => onPress(value),
  });

  const cellStyle = useCellStyle({
    isSelected,
    isHovered,
    isDisabled,
    isFocused,
  });

  useEffect(() => {
    if (isFocused) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ref?.current?.focus();
    }
  }, [isFocused]);

  return (
    <div
      {...mergeProps(hoverProps, {
        ...pressProps,
        role: "button",
        "aria-selected": isSelected,
        "aria-disabled": isDisabled,
        "aria-label": value.toLocaleString(),
      })}
      tabIndex={isDisabled ? -1 : 0}
      ref={ref}
      css={{ ...cellStyle, ...style }}
    >
      {children}
    </div>
  );
};

export const DayCell = ({
  isCurrentMonth,
  value,
  isSelected,
  ...props
}: CellProps & {
  isCurrentMonth: boolean;
}) => {
  const uiColors = useUiColors();

  return (
    <BaseCell
      {...props}
      value={value}
      isSelected={isSelected}
      style={{
        ...cssFns.border({ radius: "50%" }),
        width: "40px",
        height: "40px",
        color: isSelected
          ? uiColors.textOnControlMain
          : isCurrentMonth
            ? uiColors.text
            : uiColors.textMinor,
      }}
    >
      {value.day}
    </BaseCell>
  );
};

export const MonthCell = ({ value, ...props }: CellProps) => {
  const locale = useLocale();

  return (
    <BaseCell
      value={value}
      style={{
        textTransform: "capitalize",
        ...cssFns.margin("8px"),
        ...cssFns.border({ radius: "16px" }),
      }}
      {...props}
    >
      {`${Intl.DateTimeFormat(locale.toString(), {
        month: "short",
      }).format(new Date(value.toString()))}`}
    </BaseCell>
  );
};

export const HeaderCell = ({
  date,
  isDisabled,
}: {
  date: Temporal.PlainDate;
  isDisabled: boolean;
}) => {
  const typo = useTypo();
  const locale = useLocale();

  return (
    <div
      css={{
        width: "40px",
        height: "40px",
        ...useCellStyle({ isReadOnly: true, isDisabled }),
        ...typo({
          level: "caption1",
          weight: "regular",
          density: "tight",
        }),
      }}
    >
      {date.toLocaleString(locale.toString(), { weekday: "short" })}
    </div>
  );
};
