/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect, useState } from "react";

import { cssFns } from "@superweb/css";
import {
  Button,
  Snackbar,
  icons,
  useIsMobile,
  useTypo,
  useUiColors,
} from "@superweb/ui";

import { useMessage } from "#internal/intl";
import { useMetrika } from "#internal/metrika";

import someErrorImage from "./some-error.svg";

const showErrorPageGoal = "2bac4d9c-9e32-498d-a313-166115885183";

export const ErrorPageContent = ({
  title,
  description,
  traceId,
}: {
  title: string;
  description?: string;
  traceId?: string;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const message = useMessage();
  const { reachGoal } = useMetrika();
  const isMobile = useIsMobile();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    reachGoal(showErrorPageGoal);
  }, [reachGoal]);

  return (
    <>
      <div
        css={{
          display: "grid",
          rowGap: "32px",
          gridTemplateRows: "min-content 1fr",
          ...cssFns.padding("16px"),
          paddingBlockEnd: "50px",
        }}
      >
        <div
          css={{
            display: "grid",
            rowGap: "10px",
          }}
        >
          <div
            css={{
              ...typo({ level: "body1", weight: "medium", density: "normal" }),
            }}
          >
            {title}
          </div>
          {description && (
            <div
              css={typo({ level: "body2", weight: "medium", density: "tight" })}
            >
              {description}
            </div>
          )}
          {traceId && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <div
                css={{
                  ...typo({
                    level: "body2",
                    weight: "medium",
                    density: "tight",
                  }),
                  color: uiColors.textMinor,
                  wordBreak: "break-all",
                }}
              >
                {`trace id: ${traceId}`}
              </div>
              <Button
                size="xs"
                view="ghost"
                icon={icons.CopyOutline}
                ariaLabel={message({
                  id: "b7a47815-cc5b-460a-9509-f035e10f72fe",
                  context:
                    "Self-registration form. Error screen. Copy button aria label",
                  default: "Copy trace ID",
                })}
                onPress={() => {
                  navigator.clipboard.writeText(traceId);
                  setIsCopied(true);
                }}
              />
            </div>
          )}
        </div>
        <img
          src={someErrorImage}
          alt=""
          css={{
            maxWidth: isMobile ? "290px" : "360px",
            width: "100%",
            ...cssFns.margin("auto"),
          }}
        />
      </div>
      {isCopied && (
        <Snackbar
          text={message({
            id: "355bd40a-76cf-4d38-8c94-8c89c7c58d94",
            context: "Self-registration form. Error screen. Snackbar text",
            default: "Copied!",
          })}
          onClose={() => setIsCopied(false)}
        />
      )}
    </>
  );
};
