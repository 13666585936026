/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { DOMAttributes, ReactNode, RefObject } from "react";

import { cssFns, type Style } from "@superweb/css";

import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { useUiOptions } from "../ui-options-context";

/**
 * Component for field template that puts in place: label, description, error message, input, beforeIcon and buttons.
 * @internal
 */
export const Field = ({
  disableInputContentWrap,
  enableInputAtClear,

  fieldProps,
  fieldRef,

  shrunk,
  focused,
  disabled = false,
  stretchInputElement = false,
  multiline = false,
  dir,

  label,
  icon,
  input,
  clearButton,
  toolbar,
  descriptionAndError,
  characterCounter,
  onClick,
}: {
  disableInputContentWrap?: boolean;
  enableInputAtClear?: boolean;

  fieldProps?: DOMAttributes<Element>;
  fieldRef?: RefObject<HTMLDivElement>;

  shrunk: boolean;
  focused: boolean;
  disabled?: boolean;
  stretchInputElement?: boolean;
  multiline?: boolean;
  dir?: "ltr" | "rtl";

  label: ReactNode;
  icon?: ReactNode;
  input: ReactNode;
  clearButton?: ReactNode;
  toolbar?: ReactNode;
  descriptionAndError: ReactNode;
  characterCounter?: ReactNode;

  onClick?: () => void;
}) => {
  const uiColors = useUiColors();

  return (
    <div
      dir={dir}
      css={{
        display: "inline-grid",
        gridTemplateRows: "1fr auto",
      }}
    >
      <div
        {...fieldProps}
        ref={fieldRef}
        css={{
          height: multiline ? "auto" : "56px",
          boxSizing: "border-box",

          display: "grid",
          gridTemplateColumns: icon
            ? "[icon] min-content [label] auto [clear] min-content [toolbar] min-content [end]"
            : "[label] 1fr [clear] min-content [toolbar] min-content [end]",

          ...cssFns.border({
            width: "2px",
            radius: "16px",
            style: "solid",
            color: focused ? uiColors.text : "transparent",
          }),

          backgroundColor: focused
            ? uiColors.backgroundFloating
            : uiColors.controlMinor,
          opacity: disabled ? "0.5" : "1",
        }}
        onClick={onClick}
      >
        {enableInputAtClear && (
          <div
            css={{
              gridColumnStart: "1",
              gridColumnEnd: "end",
              gridRowStart: "1",
              gridRowEnd: "2",
              display: "grid",
              gridTemplateColumns: "1fr 46px",
            }}
          >
            <div
              css={{
                gridColumnStart: "1",
                gridColumnEnd: "2",
                gridRowStart: "1",
                gridRowEnd: "2",
                width: focused ? "100%" : "calc(100% + 32px)",
                color: uiColors.text,
                opacity: shrunk ? "1" : "0",
              }}
            >
              {input}
            </div>
            <div
              css={{
                gridColumnStart: "2",
                gridColumnEnd: "3",
                gridRowStart: "1",
                gridRowEnd: "2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "46px",
              }}
            >
              {clearButton}
            </div>
          </div>
        )}

        {!enableInputAtClear && (
          <div
            css={{
              alignSelf: "start",
              color: uiColors.text,
              opacity: shrunk ? "1" : "0",
              ...(stretchInputElement
                ? {
                    gridColumnStart: "1",
                    gridColumnEnd: "end",
                    gridRowStart: "1",
                    gridRowEnd: "2",
                  }
                : {
                    marginBlockStart: "24px",
                    marginInlineStart: icon ? "8px" : "14px",
                    gridColumnStart: "label",
                    gridColumnEnd: "clear",
                    gridRowStart: "1",
                    gridRowEnd: "2",
                  }),
              ...(disableInputContentWrap && {
                whiteSpace: "nowrap",
              }),
            }}
          >
            {input}
          </div>
        )}

        {icon && (
          <div
            css={{
              gridColumnStart: "icon",
              gridColumnEnd: "label",
              gridRowStart: "1",
              gridRowEnd: "2",
              alignSelf: "start",
              marginBlockStart: "6px",
              marginInlineStart: "6px",
              color: uiColors.text,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            {icon}
          </div>
        )}

        <div
          css={{
            alignSelf: "start",
            gridColumnStart: "label",
            gridColumnEnd: "toolbar",
            gridRowStart: "1",
            gridRowEnd: "2",
            marginBlockStart: "18px",
            marginInlineStart: icon ? "8px" : "14px",
            pointerEvents: "none",
          }}
        >
          {label}
        </div>

        {!enableInputAtClear && (
          <div
            css={{
              gridColumnStart: "clear",
              gridColumnEnd: "toolbar",
              gridRowStart: "1",
              gridRowEnd: "2",
              alignSelf: "center",
              marginInlineStart: "8px",
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            {clearButton}
          </div>
        )}

        <div
          css={{
            gridColumnStart: "toolbar",
            gridColumnEnd: "end",
            gridRowStart: "1",
            gridRowEnd: "2",
            alignSelf: "center",
            marginInlineEnd: "8px",
          }}
        >
          {toolbar && <div css={{ display: "flex" }}>{toolbar}</div>}
        </div>
      </div>

      {characterCounter || descriptionAndError ? (
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {descriptionAndError}
          {characterCounter}
        </div>
      ) : null}
    </div>
  );
};

export const useTextInputStyle = ({
  disabled,
  icon,
}: {
  disabled: boolean;
  icon: boolean;
}): Style => {
  const uiOptions = useUiOptions();
  const enableInputAtClear = uiOptions.experimental?.enableInputAtClear;

  const typo = useTypo();
  const uiColors = useUiColors();

  return {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",

    ...cssFns.margin("0"),
    ...cssFns.border({ style: "none", radius: "16px", width: "0" }),
    paddingBlockStart: "24px",
    paddingBlockEnd: "10px",
    paddingInlineStart: icon ? "54px" : "14px",
    paddingInlineEnd: enableInputAtClear ? "0" : "46px",

    ...typo({
      level: "body2",
      density: "tight",
      weight: "regular",
    }),
    color: uiColors.text,
    backgroundColor: "transparent",
    outlineStyle: "none",
    cursor: disabled ? "default" : "text",
  };
};
